import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { routes } from 'routes';

import { faCircleCheck, faPieChart } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import { mxrightApi } from 'resources/mxright';

import ScoreCircle from 'components/ScoreCircle';

import { EMAIL_REPORT_GROUPS } from 'pages/Results';

import './styles.scss';

const iconsMap = {
  success: {
    icon: faCircleCheck,
    color: '#17EDC3',
  },
  failed: {
    icon: faCircleExclamation,
    color: '#FB3A61',
  },
};

const EmailInfrastructure = ({ email, showReport }) => {
  const navigate = useNavigate();

  const { data: menuItems } = mxrightApi.useMenuItemsList(email);
  const { data: commonReportData } = mxrightApi.useCommonData(email);

  if (!commonReportData || !menuItems) {
    return null;
  }

  return (
    <div className="email-infrastructure">
      <div className="email-infrastructure-header">
        <p className="email-infrastructure-header-title">Email Infrastructure</p>

        {showReport && (
        <div
          className="email-infrastructure-header-report"
          onClick={() => navigate(routes.result.path.replace(':email', email))}
          aria-hidden
        >
          <FontAwesomeIcon fontSize={16} icon={faPieChart} color="#FFFFFF8F" />
          <p className="email-infrastructure-header-report-text">View Infrastructure Report</p>
        </div>
        )}
      </div>

      <div className="email-infrastructure-content">
        <div className="email-infrastructure-content-diagram">
          <ScoreCircle
            mxrScoreTotal={commonReportData ? 10 : undefined}
            mxrScoreTotalPass={commonReportData?.totalScore}
            ratingColor={commonReportData?.rating?.color}
            ratingLabel={commonReportData?.rating?.label}
          />
        </div>

        <div className="email-infrastructure-content-items-wrapper">
          {[
            [EMAIL_REPORT_GROUPS.SMTP, EMAIL_REPORT_GROUPS.SPAM, EMAIL_REPORT_GROUPS.HONEYPOT],
            [EMAIL_REPORT_GROUPS.DNS, EMAIL_REPORT_GROUPS.DKIM, EMAIL_REPORT_GROUPS.SPAM_ASSASSIN],
          ].map((row) => (
            <div className="email-infrastructure-content-items-row" key={row[0].id}>
              {row.map((emailGroup) => {
                const menuItem = menuItems.find((item) => item.id === emailGroup);

                const status = menuItem.numFailed ? 'failed' : 'success';
                const { icon, color } = iconsMap[status];

                return (
                  <div
                    className="email-infrastructure-content-item"
                    key={menuItem.id}
                  >
                    <FontAwesomeIcon fontSize={20} icon={icon} color={color} />
                    <p>{menuItem.name}</p>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

EmailInfrastructure.propTypes = {
  email: PropTypes.string.isRequired,
  showReport: PropTypes.bool,
};

EmailInfrastructure.defaultProps = {
  showReport: false,
};

export default EmailInfrastructure;
